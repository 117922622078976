<template>
    <div class="message-panel">
        <message-card v-for="message in messages"
                      :key="message.id"
                      :message="message">
        </message-card>
    </div>
</template>

<script>
    import EventBus from "../../routerEventBus";

    export default {
        name: "MessagesPanel",
        data() {
            return {
                messages: []
            }
        },
        methods: {
            generateId() {
                let result           = '';
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < 5; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            deleteMessage(id) {
                let message = document.querySelector('#message-' + id);

                if(message !== null) {
                    message.classList.add('hide');
                }

                setTimeout(() => {
                    this.messages.forEach((message, index) => {
                        if(message.id === id) {
                            this.messages.splice(index, 1);
                        }
                    });
                }, 500);
            },
            setPersistence(response, message) {
                if(response.body.persistence !== undefined) {
                    message.persistence = response.body.persistence;
                } else {
                    message.persistence = 0;
                }

                return message;
            }
        },
        created() {
            this.$bus.$on('unhandled-error', (response) => {
                let message = {
                    type: 'danger',
                    content: 'Une erreur interne est survenue. Nous sommes sur le coup !',
                    id: this.generateId(),
                    icon: 'error'
                };

                message = this.setPersistence(response, message);

                this.messages.push(message);
            });

            this.$bus.$on('handled-error', (response) => {
                let message = {
                    type: 'danger',
                    content: response.body.message,
                    id: this.generateId(),
                    icon: 'error'
                };

                message = this.setPersistence(response, message);

                this.messages.push(message);
            });

            this.$bus.$on('success-request', (response) => {
                if(response.body.message !== undefined && response.body.message !== null) {
                    let message = {
                        type: 'success',
                        content: response.body.message,
                        id: this.generateId(),
                        icon: 'check_circle'
                    };

                    message = this.setPersistence(response, message);

                    this.messages.push(message);
                }
            });

            this.$bus.$on('adhesion-success-notif', (message) => {
                let msg = {
                    type: 'success',
                    content: message,
                    id: this.generateId(),
                    icon: 'check_circle',
                    toAppend: true
                };

                this.messages.push(msg);

                window.setTimeout(() => {
                    this.deleteMessage(msg.id)
                }, 5000);
            });

            this.$bus.$on('adhesion-error-notif', (message) => {
                let msg = {
                    type: 'danger',
                    content: message,
                    id: this.generateId(),
                    icon: 'check_circle',
                    toAppend: true
                };

                this.messages.push(msg);

                window.setTimeout(() => {
                    this.deleteMessage(msg.id)
                }, 5000);
            });

            this.$bus.$on('delete-message', (id) => this.deleteMessage(id));

            EventBus.$on('router-change', () => {
                this.messages.forEach(message => {
                    if(message.persistence === 0) {
                        this.deleteMessage(message.id);
                    } else {
                        message.persistence--;
                    }
                });
            })
        },
        beforeDestroy() {
            this.$bus.$off('unhandled-error');
            this.$bus.$off('handled-error');
            this.$bus.$off('success-request');
            this.$bus.$off('adhesion-success-notif');
            this.$bus.$off('adhesion-error-notif');
            this.$bus.$off('delete-message');

            EventBus.$off('router-change');
        }
    }
</script>