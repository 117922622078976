<template>
    <div class="adhesion container" v-show="cadh !== null">
        <div class="container pb-4" v-show="!showSavedMessage && !loading && !displayStripeModal">
            <div class="p-3 logo">
                <img :src="logo.doc_url" v-if="logo !== null" style="max-height: 120px;">
            </div>

            <div class="main-content text-center" v-html="getConfigKey('titre_general')"></div>

            <h4 class="mt-3 mb-3 main-content text-center" v-if="cadhRequest !== null">
                {{ getName() }}
            </h4>

            <div class="mt-3 mb-5 main-content" v-html="getConfigKey('introduction')"></div>

            <offers v-if="cadh !== null"
                    class="mt-4"
                    :criteria-families="cadh.configuration.criteria_families"
                    :selectedOffers="selectedOffers"
                    :config="config"
                    :config-id="cadh.configuration.id">
            </offers>

            <options :options="options"
                     :config="config"
                     :options-groups="optionsGroups"
                     :selected-options="selectedOptions"
                     v-if="cadh !== null && cadh.configuration.options.length && filtered">
            </options>

            <informations :informations="informations"
                          :config="config"
                          v-if="cadh !== null && cadh.configuration.informations.length && filtered && !loading">
            </informations>

            <basket v-if="filtered"
                    :config="cadh.configuration"
                    :selected-options="selectedOptions"
                    :selected-offers="selectedOffers">
            </basket>

            <div class="w-100 text-center">
                <md-button class="md-raised" v-if="filtered" @click="saveAnswer">
                    Enregistrer
                </md-button>
            </div>
        </div>

        <div v-if="displayThanks" class="container pb-4 pt-4">
            <div class="logo-center text-center">
                <img :src="logo.doc_url" v-if="logo !== null">
            </div>

            <div class="mt-4 main-content" v-html="getConfigKey('remerciement_1')"></div>

            <div class="mt-3 text-center" v-if="selectedMP.description !== undefined &&
                                                (selectedMP.description !== null ||
                                                selectedMP.document !== null)">
                <hr>
                <div class="text-left main-content">{{ selectedMP.description }}</div>
                <div class="mt-2" v-if="selectedMP.document !== null">
                    <img :src="'/' + selectedMP.document">
                </div>
            </div>
        </div>

        <div v-if="loading" class="loading">
            <md-progress-spinner :md-diameter="25"
                                 :md-stroke="3"
                                 md-mode="indeterminate">
            </md-progress-spinner>
        </div>

        <md-dialog-confirm
            :md-active.sync="displayConfirmNoPaiement"
            md-title="Confirmation de votre demande"
            md-confirm-text="Oui"
            md-cancel-text="Non"
            @md-confirm="answerWithoutPaiement()" />

        <md-dialog-confirm
            :md-active.sync="displayConfirmPaiement"
            md-title="Confirmation de votre demande"
            md-confirm-text="Oui"
            md-cancel-text="Non"
            @md-confirm="answerWithPaiement()" />

        <div class="container" style="padding: 50px !important;" v-if="showSavedMessage">
            Votre réponse a été enregistrée. Si vous souhaitez consulter la réponse sur Hubo, pensez à recharger la page
            du navigateur.
        </div>
    </div>
</template>

<script>
import Basket from "./tools/Basket";
import Informations from "./tools/Informations";
import Options from "./tools/Options";
import Offers from "./tools/Offers";

export default {
    name: "EditAdhesion",
    components: { Basket, Informations, Options, Offers },
    data() {
        return {
            cadh: null,
            cadhRequest: null,
            filtered: false,
            options: [],
            optionsGroups: [],
            selectedOptions: [],
            selectedOffers: [],
            displayStripe: false,
            displayBasket: false,
            displayThanks: false,
            displayStripeModal: false,
            displayConfirmPaiement: false,
            displayConfirmNoPaiement: false,
            submitted: false,
            prestataire: {
                email: '',
                first_name: '',
                last_name: ''
            },
            extraInformation: null,
            selectedMP: {},
            moyensPaiement: [],
            stripePk: null,
            stripeSk: null,
            optionsCard: null,
            optionsCVC: null,
            optionsExpired: null,
            loading: false,
            config: {},
            clientSecret: null,
            stripe: null,
            card: null,
            cadhAnswerId: null,
            msgError: null,
            apiUrl: process.env.VUE_APP_API_URL,
            logo: null,
            answerId: null,
            answer: null,
            informations: [],
            showSavedMessage: false
        }
    },
    methods: {
        getRequest(token) {
            this.loading = true;

            this.$http.get(this.apiUrl + '/api/adhesion/v1/' + token + '/true').then(res => {
                if(res.body.result === 'success') {
                    let cadh = res.body.data.cadh;

                    this.options = res.body.data.options;
                    this.optionsGroups = res.body.data.optionsGroups;
                    this.cadhRequest = res.body.data.adhesion;
                    this.prestataire.email = this.cadhRequest.email_to;
                    this.moyensPaiement = cadh.configuration.payment_options;
                    this.stripePk = res.body.data.stripePK;
                    this.stripeSk = res.body.data.stripeSK;
                    this.logo = res.body.data.logo
                    this.config = res.body.data.cadh.configuration

                    cadh.configuration.informations.forEach(info => {
                        info.selected = false;
                    });

                    this.cadh = cadh;

                    this.setOptionsPrice();
                    this.selectOffers()
                    this.selectOptions()
                    this.selectInformations()

                    this.loading = false;
                }
            }, () => {
                // géré par interceptor
            })
        },
        buildPayload() {
            let options = [];
            let offers = [];
            let informations = [];

            this.selectedOptions.forEach(option => {
                let opt = {
                    id: option.cadh_option_id,
                    quantity: option.quantity
                };

                options.push(opt);
            });

            this.selectedOffers.forEach(offer => {
                let off = {
                    id: offer.cadh_offer_id,
                    quantity: offer.quantity
                };

                offers.push(off);
            });

            this.informations.forEach(info => {
                if(info.selected) {
                    informations.push(info.id);
                }
            });

            let payload = {
                cadhFormId: this.cadhRequest.id,
                amount: this.finalPrice * 100,
                extraInfo: this.extraInformation,
                options: options,
                offers: offers,
                informations: informations
            };

            return payload;
        },
        setOptionsPrice() {
            this.options.billable.forEach(option => {
                if(option.price !== undefined) {
                    option.price = option.price / 100;
                }
            });

            this.options.non_billable.forEach(option => {
                if(option.price !== undefined) {
                    option.price = option.price / 100;
                }
            });
        },
        getName() {
            let name = ''

            if (this.cadhRequest.provider !== null) {
                name = this.cadhRequest.provider.last_version.name
            } else if(this.cadhRequest.entity !== null) {
                name = this.cadhRequest.entity.name
            }

            return name
        },
        getConfigKey(key) {
            if(this.cadh === null) {
                return null
            }

            let config = this.cadh.configuration.configuration_keys.find(configKey => configKey.key === key)

            if(config !== null && config !== undefined) {
                config = config.value
            }

            return config
        },
        getAnswer() {
            this.$http.get(this.apiUrl + '/api/adhesion/v1/answer/' + this.answerId).then(res => {
                this.getRequest(res.body.data.answer.form.token)

                this.answer = res.body.data.answer
            })
        },
        selectOffers() {
            this.selectedOffers = this.answer.offers.map(offer => {
                return {
                    cadh_offer_id: offer.offer.id,
                    quantity: offer.quantity,
                    name: offer.offer.label,
                    priceU: parseInt(offer.offer.price / 100),
                    priceT: parseInt(offer.offer.price / 100),
                    inclused: offer.offer.inclused,
                    multiple: offer.offer.multiple
                }
            })
        },
        selectOptions() {
            this.selectedOptions = this.answer.options.map(option => {
                return {
                    cadh_option_id: option.id,
                    quantity: option.pivot.quantity,
                    immediate: option.immediate,
                    priceU: parseInt(option.price / 100),
                    priceT: parseInt(option.price / 100) * option.pivot.quantity,
                    multiple: option.multiple,
                    name: option.name
                }
            })
        },
        selectInformations() {
            this.informations = this.cadh.configuration.informations

            this.answer.informations.forEach(info =>{
                this.informations.forEach((_info, index) => {
                    if(_info.id === info.id) {
                        this.informations[index].selected = true
                    }
                })
            })
        },
        saveAnswer() {
            let payload = this.buildPayload()

            this.loading = true

            this.$http.post(this.apiUrl + '/api/adhesion/v1/update', payload).then(res => {
                this.loading = false

                this.showSavedMessage = true
            })
        }
    },
    computed: {
        finalPrice() {
            let price = 0;

            this.selectedOptions.forEach(option => {
                if(option.priceT !== undefined && option.immediate) {
                    price += option.priceT;
                }
            });

            this.selectedOffers.forEach(offer => {
                if(offer.priceT !== undefined) {
                    price += offer.priceT;
                }
            });

            this.displayBasket = this.selectedOffers.length + this.selectedOptions.length > 0;

            return price;
        },
        anythingSelected() {
            let selected = false;

            if(this.selectedOptions.length + this.selectedOffers.length > 0) {
                selected = true;
            }

            this.cadh.configuration.informations.forEach(info => {
                if(info.selected && !selected) {
                    selected = true;
                }
            });

            return selected;
        }
    },
    created() {
        this.answerId = atob(this.$route.params.hash)
        this.getAnswer()

        this.$bus.$on('filtered-offers', () => {
            this.filtered = true;
        });
    },
    beforeDestroy() {
        this.$bus.$off('filtered-offers');
    }
}
</script>
