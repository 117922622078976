<template>
    <div class="adhesion container">
        <div class="container pb-4" v-if="!loading">

            <div class="p-3 logo" v-if="! customLogo">
                <img :src="logo.doc_url" v-if="logo !== null" style="max-height: 120px;">
            </div>
            <div class="p-3 mb-3 logo" v-else>
                <img :src="customLogo">
            </div>

            <div class="main-content text-center" v-html="getConfigKey('titre_general')"></div>

            <h4 class="mt-3 mb-3 main-content text-center" v-if="showOfferName">
                [NOM DU PRESTATAIRE]
            </h4>

            <div class="mt-3 mb-5 main-content" v-html="getConfigKey('introduction')"></div>

            <offers class="mt-4"
                    :criteria-families="config.criteria_families"
                    :selectedOffers="selectedOffers"
                    :config="config"
                    :config-id="config.id">
            </offers>

            <options :options="options"
                     :config="config"
                     :options-groups="optionsGroups"
                     v-if="filtered"
                     :selected-options="selectedOptions">
            </options>

            <informations :informations="config.informations"
                          v-if="filtered"
                          :config="config">
            </informations>

            <basket v-if="displayBasket"
                    :config="config"
                    :selected-options="selectedOptions"
                    :selected-offers="selectedOffers">
            </basket>

            <div class="mt-3" v-show="anythingSelected">
                <h3 class="text-center main-title">Complément d'information</h3>

                <div class="panel panel-default mt-4 w-lg-75 ml-auto mr-auto">
                    <div class="panel-heading">Informations de la personne répondant</div>

                    <div class="panel-body">
                        <div class="form-group d-md-flex">
                            <label class="col-md-2 col-form-label">Nom</label>
                            <div class="col-md-10 input-group">
                                <input type="text" name="last_name" class="form-control">
                            </div>
                        </div>

                        <div class="form-group d-md-flex">
                            <label class="col-md-2 col-form-label">Prénom</label>
                            <div class="col-md-10 input-group">
                                <input type="text" name="first_name" class="form-control">
                            </div>
                        </div>

                        <div class="form-group d-md-flex">
                            <label class="col-md-2 col-form-label">E-mail</label>
                            <div class="col-md-10 input-group">
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>

                <p class="w-lg-75 ml-auto mr-auto mt-3" v-html="getConfigKey('extra_field_title')">
                </p>

                <div class="input-group w-lg-75 ml-auto mr-auto">
                    <textarea rows="6" class="form-control" v-model="extraInformation"></textarea>
                </div>

                <div class="action-buttons text-center mt-3">
                    <md-button v-if="finalPrice === 0"
                               class="md-raised"
                               disabled>
                        Confirmer mon <span v-if="isPartenariat">partenariat</span><span v-if="!isPartenariat">adhésion</span>
                    </md-button>

                    <div v-show="finalPrice !== 0" class="w-lg-75 ml-auto mr-auto">
                        <div>
                            <div class="text-left">
                                <label class="col-form-label">Moyen de paiement</label>
                            </div>

                            <div class="text-left">
                                <p v-html="getConfigKey('payment_info')"></p>
                            </div>

                            <div>
                                <select class="form-control" v-model="selectedMP">
                                    <option v-for="paiement in config.payment_options"
                                            :key="paiement.id"
                                            :value="paiement">
                                        {{ paiement.label }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="paiement-card mt-5" v-show="selectedMP.code === '1'">
                            <div class="paiement-card-title">Paiement sécurisé par carte bancaire</div>
                            <div class="paiement-card-body">
                                <div class="text-left font-weight-bold mb-5">
                                    Montant total à payer : {{ finalPrice }}€
                                </div>
                                <div class="d-flex">
                                    <div class='credit-card-inputs w-50'>
                                        <form method="POST">
                                            <input type="hidden" name="token" />
                                            <div id="card" class="field"></div>
                                            <div id="card-errors" role="alert"></div>
                                        </form>
                                    </div>

                                    <div class="w-50 p-3">
                                        <div class="d-flex card-types flex-wrap flex-row-reverse">
                                            <img class="m-2" src="/images/cadh/visa.png">
                                            <img class="m-2" src="/images/cadh/master-card.png">
                                            <img class="m-2" src="/images/cadh/american-express.png">
                                            <img class="m-2" src="/images/cadh/discover.png">
                                            <img class="m-2" src="/images/cadh/jcb.png">
                                            <img class="m-2" src="/images/cadh/diners-club.png">
                                        </div>
                                    </div>
                                </div>
                                <div v-if="msgError !== null" class="alert alert-danger">
                                    {{ this.msgError }}
                                </div>
                            </div>
                        </div>

                        <div class="mt-3" v-if="selectedMP.id !== undefined">
                            <md-button class="md-raised"
                                       v-if="selectedMP.code !== '1'"
                                       disabled>
                                Confirmer mon <span v-if="isPartenariat">partenariat</span><span v-if="!isPartenariat">adhésion</span>
                            </md-button>

                            <md-button class="md-raised" v-else disabled>
                                Confirmer et payer mon <span v-if="isPartenariat">partenariat</span><span v-if="!isPartenariat">adhésion</span>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loading" class="loading">
            <md-progress-spinner :md-diameter="25"
                                 :md-stroke="3"
                                 md-mode="indeterminate">
            </md-progress-spinner>
        </div>
    </div>
</template>

<script>
import Offers from "./tools/Offers";
import Options from "./tools/Options";
import Informations from "./tools/Informations";
import Basket from "./tools/Basket";
export default {
    name: "Preview",
    components: {Basket, Informations, Options, Offers},
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            logo: null,
            config: null,
            options: null,
            optionsGroups: null,
            loading: false,
            selectedOptions: [],
            selectedOffers: [],
            selectedMP: [],
            displayBasket: false,
            extraInformation: null,
            msgError: null,
            filtered: false
        }
    },
    methods: {
        getAccountConfig() {
            let hash      = this.$route.params.hash
            let accountId = atob(hash)

            this.loading = true

            this.$http.get(this.apiUrl + '/api/adhesion/v1/config/' + accountId).then(res => {
                this.logo          = res.body.data.logo
                this.config        = res.body.data.configuration
                this.options       = res.body.data.options
                this.optionsGroups = res.body.data.optionsGroups

                this.setOptionsPrice()

                this.loading = false
            })
        },
        setOptionsPrice() {
            this.options.billable.forEach(option => {
                if(option.price !== undefined) {
                    option.price = option.price / 100;
                }
            });

            this.options.non_billable.forEach(option => {
                if(option.price !== undefined) {
                    option.price = option.price / 100;
                }
            });
        },
        getConfigKey(key) {

            if(this.cadh === null) {
                return null
            }

            let config = this.config.configuration_keys.find(configKey => configKey.key === key)

            if(config !== null && config !== undefined) {
                config = config.value
            }

            if (config === undefined && key == 'extra_field_title') {

                config = "Informations complémentaires que vous souhaiteriez transmettre à l'office de tourisme."
            }

            if (config === undefined && key == 'basket_field_title') {

                config = "<h3>Récapitulatif de mon adhésion</h3>"
            }

            return config
        }
    },
    computed: {
        finalPrice() {
            let price = 0;

            this.selectedOptions.forEach(option => {
                if(option.priceT !== undefined && option.immediate) {
                    price += option.priceT;
                }
            });

            this.selectedOffers.forEach(offer => {
                if(offer.priceT !== undefined) {
                    price += offer.priceT;
                }
            });

            this.displayBasket = this.selectedOffers.length + this.selectedOptions.length > 0;

            return price;
        },
        anythingSelected() {
            let selected = false;

            if(this.selectedOptions.length + this.selectedOffers.length > 0) {
                selected = true;
            }

            this.config.informations.forEach(info => {
                if(info.selected && !selected) {
                    selected = true;
                }
            });

            return selected;
        },
        customLogo() {
            let customLogo = this.getConfigKey('custom_logo')

            console.log("customLogo : " + customLogo)

            if (customLogo === undefined) {
                return false
            }
            else {
                return this.apiUrl + '/' + customLogo
            }
        },
        showOfferName() {
            let offerNameDeactivated = this.getConfigKey('offer_name_deactivated')

            console.log("offerNameDeactivated : " + offerNameDeactivated)

            if (offerNameDeactivated === '1') {
                return false
            }
            else {
                return true
            }
        },
        isPartenariat() {

            let is_partenariat = this.getConfigKey('global_partenariat')

            if (is_partenariat === '1') {
                return true
            }
            else {
                return false
            }
        },
        initStripe() {
            this.stripe = Stripe(this.stripePk);
            let elements = this.stripe.elements();

            let elementStyles = {
                base: {
                    color: '#32325D',
                    fontWeight: 500,
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',

                    '::placeholder': {
                        color: '#9d9898',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    }
                },
                invalid: {
                    color: '#E25950',

                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            };

            let elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            };

            let card = elements.create('card', {style: elementStyles, classes: elementClasses});
            card.mount('#card');

            card.addEventListener('change', event => {
                let displayError = document.getElementById('card-errors');

                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });

            this.card = card;
        }
    },
    created() {
        this.getAccountConfig()

        this.$bus.$on('filtered-offers', () => {
            this.filtered = true;
        });
    },
    beforeDestroy() {
        this.$bus.$off('filtered-offers');
    }
}
</script>
