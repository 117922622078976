import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import EventBus from "./routerEventBus";

Vue.use(VueRouter);
Vue.component('router-link', Vue.options.components.RouterLink);
Vue.component('router-view', Vue.options.components.RouterView);

import Adhesion from "./components/Adhesion";
import Preview from "./components/Preview";
import EditAdhesion from "./components/EditAdhesion";
import Result from "./components/Result";

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/adhesion/:token',       component: Adhesion },
        { path: '/preview/:hash',         component: Preview },
        { path: '/adhesion/update/:hash', component: EditAdhesion },
        { path: '/result',                component: Result }
    ]
});

router.beforeEach((to, from, next) => {
    NProgress.start();

    if(to.meta.title !== undefined) {
        document.title = 'Hubo – ' + to.meta.title;
    } else {
        document.title = 'Hubo';
    }

    next();
});

router.afterEach((to, from) => {
    EventBus.$emit('router-change');

    NProgress.done();
});

export default router;
