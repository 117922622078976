<template>
    <div class="mt-3">
        <div class="main-title text-center"
             v-if="options.billable.length || options.non_billable.length"
             v-html="$parent.getConfigKey('options_titre_complement')">
        </div>

        <div class="mt-3 main-content" v-if="options.billable.length">
            <h4>Options payables avec votre adhésion</h4>

            <option-list
                :options="options.billable"
                :selected-options="selectedOptions"
                :config="config"
                :options-groups="optionsGroups.billable">
            </option-list>
        </div>

        <div class="mt-5 main-content" v-if="options.non_billable.length">
            <h4>Options à réserver (paiement ultérieur sur facturation)</h4>

            <div v-html="$parent.getConfigKey('non_billable_options_info_1')"></div>

            <option-list
                :options="options.non_billable"
                :selected-options="selectedOptions"
                :config="config"
                :options-groups="optionsGroups.non_billable">
            </option-list>
        </div>
    </div>
</template>

<script>
    import OptionList from "./OptionList";
    export default {
        name: "Options",
        components: {OptionList},
        props: ['options', 'selectedOptions', 'config', 'optionsGroups'],
        computed: {
            totalPrice() {
                let price = 0;

                this.selectedOptions.forEach(option => {
                    if(option.priceT !== undefined) {
                        price += option.priceT;
                    }
                });

                return price;
            }
        },
        created() {
            this.options.billable.forEach(option => {
                 option.paddingLeft = option.level * 40;
            });

            this.options.non_billable.forEach(option => {
                option.paddingLeft = option.level * 40;
            });
        }
    }
</script>
