<template>
    <div></div>
</template>

<script>
export default {
    name: "Result",
    mounted() {
        window.top.postMessage('3DS-authentication-complete');
    }
}
</script>