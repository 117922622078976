<template>
    <div id="basket">
        <div class="text-center main-title">
            <div v-html="getConfigKey('basket_field_title')"></div>
        </div>

        <div v-if="selectedOffers.length" class="mt-3 main-content">
            <h3 class="mb-1 basket-title" v-if="selectedOffers.length > 1">
                Offres sélectionnées ({{ selectedOffers.length }})
            </h3>

            <h3 class="mb-1 basket-title" v-else>
                Offre sélectionnée ({{ selectedOffers.length }})
            </h3>

            <md-table>
                <md-table-row>
                    <md-table-head>Nom</md-table-head>
                    <md-table-head>Prix unitaire <span v-if="isPriceHt">HT</span></md-table-head>
                    <md-table-head>Quantité</md-table-head>
                    <md-table-head>Prix <span v-if="isPriceHt">HT</span></md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>

                <md-table-row v-for="offer in selectedOffers" :key="offer.cadh_offer_id">
                    <md-table-cell>
                        {{ offer.name }}
                        <span style="color: red" v-if="offer.inclused"> - inclus</span>
                    </md-table-cell>
                    <md-table-cell>{{ offer.priceU }}</md-table-cell>
                    <md-table-cell>
                        <input type="number"
                               class="form-control"
                               min="1"
                               :disabled="offer.multiple === 1"
                               style="width: 60px;"
                               @input="updateTotalOfferPrice(offer)"
                               v-model="offer.quantity">
                    </md-table-cell>
                    <md-table-cell>{{ offer.priceT }}</md-table-cell>
                    <md-table-cell>
                        <md-button class="md-raised md-accent"
                                   v-if="!offer.inclused"
                                   @click="deleteOffer(offer.cadh_offer_id)">
                            Supprimer
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>

            <div class="mt-3" v-if="selectedOffers.length">
                <h4 class="strong-title">Montant total <span v-if="isPriceHt">HT</span> des offres : {{ totalOffersPrice }}€</h4>
            </div>
        </div>

        <div v-if="selectedOptions.length" class="mt-5 main-content">
            <h3 class="mb-3 basket-title" v-if="selectedOptions.length > 1">
                Options sélectionnées ({{ selectedOptions.length }})
            </h3>

            <h3 class="mb-3 basket-title" v-else>
                Option sélectionnée ({{ selectedOptions.length }})
            </h3>

            <md-table>
                <md-table-row>
                    <md-table-head>Nom</md-table-head>
                    <md-table-head>Prix unitaire <span v-if="isPriceHt">HT</span></md-table-head>
                    <md-table-head>Quantité</md-table-head>
                    <md-table-head>Prix <span v-if="isPriceHt">HT</span></md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>

                <md-table-row v-for="option in selectedOptions" :key="option.cadh_option_id">
                    <md-table-cell>
                        {{ option.name }}
                        <span style="color: red" v-if="!option.immediate"> - facturé ultérieurement</span>
                    </md-table-cell>
                    <md-table-cell>{{ option.priceU }}</md-table-cell>
                    <md-table-cell>
                        <input type="number"
                               class="form-control"
                               min="1"
                               :disabled="option.multiple"
                               style="width: 60px;"
                               @input="updateTotalOptionPrice(option)"
                               v-model="option.quantity">
                    </md-table-cell>
                    <md-table-cell>{{ option.priceT }}</md-table-cell>
                    <md-table-cell>
                        <md-button class="md-raised md-accent" @click="deleteOption(option.cadh_option_id)">
                            Supprimer
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>

            <div class="mt-1" v-if="selectedOptions.length">
                <h4 class="strong-title">Montant total <span v-if="isPriceHt">HT</span> des options : {{ totalOptionsPrice }}€</h4>
                <h4 class="strong-title">Montant total <span v-if="isPriceHt">HT</span> des options (facturable) : {{ totalOptionsFacturable }}€</h4>
            </div>
        </div>

        <h3 class="strong-title text-center mt-5">
            Montant total <span v-if="isPriceHt">HT</span> : {{ totalPriceFacturable }}€
        </h3>
    </div>
</template>

<script>
    export default {
        name: "Basket",
        props: ['selectedOffers', 'selectedOptions', 'config'],
        methods: {
            insertOption(option) {
                let quantity = document.querySelector('#qt-opt-' + option.option_id).value;

                if(quantity === '') {
                    quantity = 1;
                } else {
                    quantity = parseInt(quantity);
                }

                document.querySelector('#qt-opt-' + option.option_id).value = null;

                let exist = false;

                this.selectedOptions.forEach(opt => {
                    if(opt.cadh_option_id === option.option_id) {
                        exist = true;
                        opt.quantity += quantity;

                        if(opt.priceT !== undefined) {
                            opt.priceT = parseInt(opt.priceU) * opt.quantity;
                        }
                    }
                });

                if(!exist) {
                    let insert = {
                        cadh_option_id: option.option_id,
                        quantity: quantity,
                        name: option.label
                    };

                    if(option.price !== undefined && option.price !== null) {
                        insert.priceU = parseInt(option.price);
                        insert.priceT = parseInt(option.price) * quantity;
                    }

                    this.selectedOptions.push(insert);
                }
            },
            updateTotalOptionPrice(option) {
                this.checkOptionQuantity(option);

                if(option.priceT !== undefined) {
                    option.priceT = option.priceU * option.quantity;
                }
            },
            deleteOption(optionId) {
                this.selectedOptions.forEach((opt, index) => {
                    if(opt.cadh_option_id === optionId) {
                        this.selectedOptions.splice(index, 1);
                    }
                });
            },
            insertOffer() {
                let quantity = this.currentOfferQuantity;
                let currentOffer = this.currentOffer;

                if(quantity === null) {
                    quantity = 1;
                } else {
                    quantity = parseInt(quantity);
                }

                let exist = false;

                this.selectedOffers.forEach(offer => {
                    if(offer.cadh_offer_id === currentOffer.id) {
                        exist = true;
                        offer.quantity += quantity;

                        if(offer.priceT !== undefined) {
                            offer.priceT = parseInt(offer.priceU) * offer.quantity;
                        }
                    }
                });

                if(!exist) {
                    let name = currentOffer.criterias[currentOffer.criterias.length - 1].code;

                    let insert = {
                        cadh_offer_id: currentOffer.id,
                        quantity: quantity,
                        name: name
                    };

                    if(currentOffer.price !== undefined && currentOffer.price !== null) {
                        insert.priceU = parseInt(currentOffer.price);
                        insert.priceT = parseInt(currentOffer.price) * quantity;
                    }

                    this.selectedOffers.push(insert);
                }
            },
            updateTotalOfferPrice(offer) {
                this.checkOfferQuantity(offer);

                if(offer.priceT !== undefined) {
                    offer.priceT = offer.priceU * offer.quantity;
                }
            },
            deleteOffer(offerId) {
                this.selectedOffers.forEach((off, index) => {
                    if(off.cadh_offer_id === offerId) {
                        this.selectedOffers.splice(index, 1);
                    }
                });

                this.checkOfferDependencies();
                this.$bus.$emit('check-offer-dependencies');
            },
            checkOfferDependencies() {
                // parcours des offres sélectionnées
                this.selectedOffers.forEach((off, index) => {
                    if(off.dependencies !== undefined) {
                        let exist = false;

                        // parcours des dépendance de l'offre sélectionnée
                        off.dependencies.forEach(offer => {

                            // vérification si la dépendance est dans la liste sélectionnée
                            this.selectedOffers.forEach((o, i) => {
                                if(offer.id === o.cadh_offer_id) {
                                    exist = true;
                                }
                            });
                        });

                        if(!off.dependencies.length) {
                            exist = true;
                        }

                        if(!exist) {
                            this.selectedOffers.splice(index, 1);
                        }
                    }
                });
            },
            checkOfferQuantity(offer) {
                if(offer.quantity === '') {
                    offer.quantity = 1;
                }
            },
            checkOptionQuantity(option) {
                if(option.quantity === '') {
                    option.quantity = 1;
                }
            },
            getConfigKey(key) {

                let config = this.config.configuration_keys.find(configKey => configKey.key === key)

                if(config !== null && config !== undefined) {
                    config = config.value
                }

                if (config === undefined && key == 'basket_field_title') {

                    config = "<h3>Récapitulatif de mon adhésion</h3>"
                }

                return config
            }
        },
        computed: {
            totalPrice() {
                return this.totalOffersPrice + this.totalOptionsPrice;
            },
            totalOffersPrice() {
                let price = 0;

                this.selectedOffers.forEach(offer => {
                    if(offer.priceT !== undefined) {
                        price += offer.priceT;
                    }
                });

                return price;
            },
            totalOptionsPrice() {
                let price = 0;

                this.selectedOptions.forEach(option => {
                    if(option.priceT !== undefined) {
                        price += option.priceT;
                    }
                });

                return price;
            },
            totalOptionsFacturable() {
                let price = 0;

                this.selectedOptions.forEach(option => {
                    if(option.priceT !== undefined && option.immediate) {
                        price += option.priceT;
                    }
                });

                return price;
            },
            totalPriceFacturable() {
                return this.totalOptionsFacturable + this.totalOffersPrice;
            },
            isPriceHt() {

                let is_price_ht = this.getConfigKey('price_ht')

                console.log('is_price_ht : ' + is_price_ht)

                if (is_price_ht === '1') {
                    return true
                }
                else {
                    return false
                }
            },
        },
    }
</script>
