<template>
    <div>
        <div class="main-content">
            <div class="form-group"
                 v-for="criteriaFamily in criteriaFamilies"
                 v-if="criteriaFamily.criterias.length && criteriaFamily.commun">

                <div class="mr-2 d-flex">
                    <label class="col-form-label">{{ criteriaFamily.label }}</label>
                    <div v-if="criteriaFamily.description !== null" class="mt-auto mb-auto ml-2">
                        <md-icon>info</md-icon>
                        <md-tooltip>
                            {{ criteriaFamily.description }}
                        </md-tooltip>
                    </div>
                </div>

                <div>
                    <select class="form-control" @input="checkSearchable($event, criteriaFamily)">
                        <option v-if="!criteriaFamily.selected"></option>
                        <option v-for="criteria in criteriaFamily.criterias"
                                :key="criteria.id"
                                :value="criteria.id">
                            {{ criteria.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="w-100 text-center mt-3 mb-3">
                <md-button class="md-raised " :disabled="!searchable" @click="getOffers">
                    Voir les offres
                </md-button>
            </div>
        </div>


        <div class="mt-3" v-if="filtered">
            <div class="main-title text-center" v-html="$parent.getConfigKey('offres_titre_complement')"></div>

            <div class="mt-4 mb-3 main-content" v-html="$parent.getConfigKey('offres_aide_1')"></div>

            <div class="mt-3 mb-5 main-content" v-if="minLevel !== null">
                <h3>Filtre secondaire</h3>

                <div class="mt-3">
                    <div>
                        <label class="col-form-label">{{ minLevel.name }}</label>
                    </div>

                    <div>
                        <select class="form-control" v-model="selectedMinLevel" @change="changeMinLevel(minLevel)">
                            <option v-for="criteria in minLevel.criterias"
                                    :key="criteria.id"
                                    :value="criteria">
                                {{ criteria.code }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div v-for="criteria in criterias"
                     class="m-3 card-offers-container"
                     :class="{'w-100': criterias.length === 1}">
                    <md-card>
                        <md-card-header class="text-center font-weight-bold">
                            {{ criteria.label }}
                        </md-card-header>
                        <md-card-content>
                            <div class="mb-3" v-html="criteria.description">
                            </div>
                            <div class="card-button">
                                <md-button class="md-raised" @click="openModal(criteria)">
                                    Sélectionner
                                </md-button>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div v-for="offer in offers" class="m-3 card-offers-container second-card-offer-container">
                    <md-card>
                        <md-card-header class="text-center font-weight-bold">
                            <div>{{ offer.name }}</div>
                            <div class="mt-3 font-weight-normal text-justify" v-html="offer.description"></div>
                        </md-card-header>
                        <md-card-content class="text-center second-card-content">
                            <div class="card-button">
                                <h4 class="text-center inclused-label" v-if="offer.inclused">
                                    [INCLUS]
                                </h4>
                                <h4 class="text-center">
                                    Prix <span v-if="isPriceHt">HT</span> : {{ offer.price }}€
                                </h4>
                                <div class="d-flex">
                                    <div class="m-auto quantity-container d-flex">
                                        <span>Quantité</span>
                                        <input :id="'qt-off-' + offer.id"
                                               value="1"
                                               type="number"
                                               :disabled="offer.inclused || offer.multiple === 1"
                                               min="1"
                                               class="form-control">
                                    </div>
                                    <div>
                                        <md-button class="md-raised"
                                                   :disabled="offer.inclused || !offer.selectable"
                                                   @click="selectOffer(offer)">
                                            Sélectionner
                                        </md-button>
                                    </div>
                                </div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>
            </div>

            <div class="mt-4 mb-3 main-content" v-html="$parent.getConfigKey('offres_aide_2')"></div>
        </div>


        <md-dialog :md-active.sync="showModal" @md-closed="closeModal">
            <md-content v-if="selectedCriteria !== null" class="m-4">
                <h3 class="mb-3">{{ selectedCriteria.code }}</h3>

                <h4 class="mb-3" v-if="hasCriteriaSelections">Préciser</h4>

                <div v-for="criteriaFamily in currentCriterias" v-if="criteriaFamily.elements.length > 1">

                    <div class="mr-2 d-flex">
                        <label class="col-form-label">{{ criteriaFamily.label }}</label>
                        <div v-if="criteriaFamily.description !== null" class="mt-auto mb-auto ml-2">
                            <md-icon>info</md-icon>
                            <md-tooltip>
                                {{ criteriaFamily.description }}
                            </md-tooltip>
                        </div>
                    </div>

                    <div>
                        <select class="form-control" @input="findSingleOffer($event, criteriaFamily)">
                            <option v-if="!criteriaFamily.selected"></option>
                            <option v-for="criteria in criteriaFamily.elements"
                                    :key="criteria.id"
                                    :value="criteria.id">
                                {{ criteria.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div v-if="searchedOffers && currentOffer !== null">
                    <div class="mt-3 text-center">
                        <h3>Prix <span v-if="isPriceHt">HT</span> : {{ currentOffer.price }}€</h3>
                    </div>

                    <div class="d-flex mt-3">
                        <div class="m-auto quantity-container d-flex">
                            <span>Quantité</span>
                            <input v-model="currentOfferQuantity" type="number" min="1" class="form-control">
                        </div>
                        <div>
                            <md-button class="md-raised" @click="insertOffer()">Ajouter</md-button>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-dialog>

        <div v-if="loading" class="loading">
            <md-progress-spinner :md-diameter="25"
                                 :md-stroke="3"
                                 md-mode="indeterminate">
            </md-progress-spinner>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Offers",
        props: ['criteriaFamilies', 'configId', 'selectedOffers', 'config'],
        data() {
            return {
                selectedCriterias: {},
                selectedNCCriterias: {},
                searchable: false,
                filtered: false,
                criterias: [],
                commonFilters: 0,
                selectedCriteria: null,
                showModal: false,
                selectedOfferPrice: null,
                addable: false,
                currentOffer: null,
                currentCriterias: [],
                searchedOffers: false,
                minLevel: null,
                selectedMinLevel: null,
                currentOfferQuantity: 1,
                loading: false,
                offers: [],
                apiUrl: process.env.VUE_APP_API_URL
            }
        },
        methods: {
            checkSearchable(e, criteriaFamily) {
                let searchable = true;

                this.criteriaFamilies.forEach(family => {
                    if(family.commun) {
                        if(family.id === criteriaFamily.id) {
                            family.selected = true;
                            this.selectedCriterias[family.name] = e.target.value;
                        }

                        if(searchable) {
                            searchable = family.selected;
                        }
                    }
                });

                this.searchable = searchable;
            },
            findSingleOffer(e, criteriaFamily) {
                let addable = true;

                this.criteriaFamilies.forEach(family => {
                    if(!family.commun) {
                        if(family.id === criteriaFamily.id) {
                            family.selected = true;
                            this.selectedNCCriterias[family.name] = e.target.value;
                        }

                        if(addable) {
                            addable = family.selected;
                        }
                    }
                });

                this.addable = addable;

                let criterias = [];

                for(let criteria in this.selectedCriterias) {
                    if(this.selectedCriterias[criteria] !== null) {
                        criterias.push(parseInt(this.selectedCriterias[criteria]));
                    }
                }

                for(let criteria in this.selectedNCCriterias) {
                    if(this.selectedNCCriterias[criteria] !== null) {
                        criterias.push(parseInt(this.selectedNCCriterias[criteria]));
                    }
                }

                let payload = {
                    criterias: criterias,
                    configId: this.configId,
                    specific: true
                };

                this.loading = true;

                this.$http.post(this.apiUrl + '/api/adhesion/v1/offer', payload).then(res => {
                    if(res.body.result === 'success') {
                        let offer = res.body.data.offer;

                        this.searchedOffers = true;

                        if(offer !== null) {
                            offer.price = offer.price / 100;
                            this.currentOffer = offer;
                        } else {
                            this.currentOffer = null;
                        }

                        this.loading = false;
                    }
                })
            },
            changeMinLevel(minLevel) {
                let ids = [];

                this.selectedCriterias[minLevel.name] = this.selectedMinLevel.id;

                for(let id in this.selectedCriterias) {
                    ids.push(parseInt(this.selectedCriterias[id]));
                }

                let payload = {
                    criterias: ids,
                    configId: this.configId
                };

                this.loading = true;

                this.$http.post(this.apiUrl + '/api/adhesion/v1/offers', payload).then(res => {
                    if(res.body.result === 'success') {
                        this.criterias = res.body.data.criterias;
                        this.loading = false;
                    }
                }, () => {
                    // géré par interceptor
                });
            },
            getOffers() {
                let ids = [];

                for(let id in this.selectedCriterias) {
                    ids.push(parseInt(this.selectedCriterias[id]));
                }

                let payload = {
                    criterias: ids,
                    configId: this.configId
                };

                this.loading = true;

                this.$http.post(this.apiUrl + '/api/adhesion/v1/offers', payload).then(res => {
                    if(res.body.result === 'success') {
                        this.criterias = res.body.data.criterias;
                        this.offers = res.body.data.offers;
                        this.filtered = true;
                        this.$bus.$emit('filtered-offers');

                        if(this.offers.length) {
                            this.checkInclusedOffers();
                            this.setOffersPrice();
                            this.updateSelectableOffers();
                        }

                        this.loading = false;
                    }
                }, () => {
                    // géré par interceptor
                });
            },
            updateSelectableOffers() {
                this.offers.forEach(offer => {
                    offer.selectable = this.hasDependencyPassed(offer);
                });

                this.$forceUpdate();
            },
            openModal(criteria) {
                for(let _criteria in this.selectedNCCriterias) {
                    this.selectedNCCriterias[_criteria] = null
                }

                this.selectedCriteria = criteria;
                this.selectedNCCriterias[criteria.label] = criteria.id;

                let ids = [];

                for(let criteria in this.selectedCriterias) {
                    if(this.selectedCriterias[criteria] !== null) {
                        ids.push(parseInt(this.selectedCriterias[criteria]));
                    }
                }

                for(let criteria in this.selectedNCCriterias) {
                    if(this.selectedNCCriterias[criteria] !== null) {
                        ids.push(parseInt(this.selectedNCCriterias[criteria]));
                    }
                }

                let payload = {
                    configId: this.configId,
                    criteriasId: ids
                };

                this.loading = true;

                this.$http.post(this.apiUrl + '/api/adhesion/v1/criterias', payload).then(res => {
                    if(res.body.result === 'success') {
                        this.$set(this, 'currentCriterias', res.body.data.criterias)
                        this.showModal = true;
                        this.loading = false;

                        this.checkIfCriteriaHasMultipleOffers()
                    }
                }, () => {
                    // géré par interceptor
                });
            },
            checkIfCriteriaHasMultipleOffers() {
                this.currentCriterias.forEach(criteria => {
                    if(criteria.elements.length === 1) {
                        this.selectedNCCriterias[criteria.name] = criteria.elements[0].id
                    }
                })

                this.findSingleOffer(null, this.selectedCriteria)
            },
            closeModal() {
                this.currentCriterias.forEach(criteria => {
                    this.selectedNCCriterias[criteria.name] = null;
                });

                this.currentCriterias = [];
                this.currentOffer = null;
                this.currentOfferQuantity = 1;
            },
            checkInclusedOffers() {
                let inclused = [];

                this.offers.forEach(offer => {
                    if(offer.inclused) {
                        inclused.push(offer);
                    }
                });

                if(!inclused.length) {
                    this.selectedOffers.forEach((offer, index) => {
                        if(offer.inclused) {
                            this.selectedOffers.splice(index, 1);
                        }
                    })
                } else {
                    inclused.forEach(off => {
                        let exist = false;

                        this.selectedOffers.forEach(offer => {
                            if(off.id === offer.cadh_offer_id) {
                                exist = true;
                            }
                        });

                        if(!exist) {
                            let insert = {
                                cadh_offer_id: off.id,
                                quantity: 1,
                                name: off.label,
                                priceU: parseFloat(off.price),
                                priceT: parseFloat(off.price),
                                inclused: true,
                                multiple: off.multiple
                            };

                            this.selectedOffers.unshift(insert);
                        }
                    })
                }
            },
            insertOffer() {
                let quantity = this.currentOfferQuantity;
                let currentOffer = this.currentOffer;

                if(quantity === null || quantity === "") {
                    quantity = 1;
                } else {
                    quantity = parseInt(quantity);
                }

                let exist = false;

                this.selectedOffers.forEach(offer => {
                    if(offer.cadh_offer_id === currentOffer.id) {
                        exist = true;
                        offer.quantity += quantity;

                        if(offer.priceT !== undefined) {
                            offer.priceT = parseFloat(offer.priceU) * offer.quantity;
                        }
                    }
                });

                if(!exist) {
                    let insert = {
                        cadh_offer_id: currentOffer.id,
                        quantity: quantity,
                        name: currentOffer.label
                    };

                    if(currentOffer.price !== undefined && currentOffer.price !== null) {
                        insert.priceU = parseFloat(currentOffer.price);
                        insert.priceT = parseFloat(currentOffer.price) * quantity;
                    }

                    this.selectedOffers.push(insert);
                }

                let span = document.createElement('span');
                span.classList.add('span-link');
                span.innerHTML = "Voir mon récapitulatif";
                span.addEventListener('click', () => {
                    this.scrollBasket();
                });

                let div = document.createElement('div');
                div.innerHTML = "L'offre « " + currentOffer.label + " » a été ajoutée<br>";
                div.append(span);

                this.$bus.$emit('adhesion-success-notif', div);

                this.showModal = false;
            },
            selectOffer(offer) {
                let quantity = document.querySelector('#qt-off-' + offer.id).value;
                let exist = false;
                let toInsert = true;

                console.log('coucou : ')

                if(quantity === '') {
                    quantity = 1;
                } else {
                    quantity = parseInt(quantity);
                }

                this.selectedOffers.forEach(off => {
                    if(off.cadh_offer_id === offer.id) {
                        exist = true;

                        if(!offer.multiple) {
                            off.quantity += quantity;

                            if(offer.priceT !== undefined) {
                                off.priceT = parseFloat(offer.priceU) * offer.quantity;
                            }
                        } else {
                            toInsert = false;
                        }
                    }
                });

                if(!exist) {
                    let insert = {
                        cadh_offer_id: offer.id,
                        quantity: quantity,
                        name: offer.label,
                        multiple: offer.multiple,
                        dependencies: offer.dependencies
                    };

                    if(offer.price !== undefined && offer.price !== null) {
                        insert.priceU = parseFloat(offer.price);
                        insert.priceT = parseFloat(offer.price) * quantity;
                    }

                    this.selectedOffers.push(insert);
                }

                let span = document.createElement('span');
                span.classList.add('span-link');
                span.innerHTML = "Voir mon récapitulatif";
                span.addEventListener('click', () => {
                    this.scrollBasket();
                });

                let div = document.createElement('div');

                if(toInsert) {
                    div.innerHTML = "L'offre « " + offer.label + " » a été ajoutée<br>";
                    div.append(span);

                    this.$bus.$emit('adhesion-success-notif', div);
                } else {
                    div.innerHTML = "L'offre « "+ offer.label +" » ne peut être sélectionnée qu'une seule fois.<br>";
                    div.append(span);

                    this.$bus.$emit('adhesion-error-notif', div);
                }

                this.updateSelectableOffers();
            },
            setOffersPrice() {
                this.offers.forEach(offer => {
                    if(offer.price !== undefined) {
                        offer.price = offer.price / 100;
                    }
                });
            },
            deleteOffer(offerId) {
                this.selectedOffers.forEach((off, index) => {
                    if(off.cadh_offer_id === offerId) {
                        this.selectedOffers.splice(index, 1);
                    }
                });
            },
            scrollBasket() {
                let element = document.getElementById("basket");
                let top = element.offsetTop;
                window.scrollTo(0, top);
            },
            hasDependencyPassed(offer) {
                let bool = true;

                offer.dependencies.forEach(dependency => {
                    let second_bool = true;

                    this.selectedOffers.forEach(off => {
                        if(off.cadh_offer_id === dependency.id) {
                            second_bool = false;
                        }
                    });

                    if(!this.selectedOffers) {
                        second_bool = false;
                    }

                    if(second_bool) {
                        bool = false;
                    }
                });

                return bool;
            }
        },
        computed: {
            totalPrice() {
                let price = 0;

                this.selectedOffers.forEach(offer => {
                    if(offer.priceT !== undefined) {
                        price += offer.priceT;
                    }
                });

                return price;
            },
            isPriceHt() {

                let is_price_ht = this.$parent.getConfigKey('price_ht')

                console.log('is_price_ht : ' + is_price_ht)

                if (is_price_ht === '1') {
                    return true
                }
                else {
                    return false
                }
            },
            hasCriteriaSelections() {
                return this.currentCriterias.filter(criteria => criteria.elements.length > 0).length > 0
            }
        },
        created() {
            this.criteriaFamilies.forEach(family => {
                family.selected = false;

                if(family.commun) {
                    this.selectedCriterias[family.name] = null;
                } else {
                    this.selectedNCCriterias[family.name] = null;
                }

                if(family.level === -1) {
                    family.selected = true;
                    this.minLevel = family;
                    this.selectedMinLevel = family.criterias[0];
                    this.selectedCriterias[family.name] = family.criterias[0].id;
                }
            });

            this.$bus.$on('check-offer-dependencies', () => {
                this.updateSelectableOffers();
            });
        },
        beforeDestroy() {
            this.$bus.$off('check-offer-dependencies');
        }
    }
</script>
