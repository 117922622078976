<template>
    <div class="adhesion container">
        <div class="container pb-4" v-show="!displayThanks && !loading && !displayStripeModal">

            <div class="p-3 logo" v-if="! customLogo">
                <img :src="logo.doc_url" v-if="logo !== null" style="max-height: 120px;">
            </div>
            <div class="p-3 mb-3 logo" v-else>
                <img :src="customLogo">
            </div>

            <div class="main-content text-center" v-html="getConfigKey('titre_general')"></div>

            <h4 class="mt-3 mb-3 main-content text-center" v-if="(cadhRequest !== null) && showOfferName">
                {{ adhesionName }}
            </h4>

            <div class="mt-3 mb-5 main-content" v-html="getConfigKey('introduction')"></div>

            <offers v-if="cadh !== null"
                    class="mt-4"
                    :criteria-families="cadh.configuration.criteria_families"
                    :selectedOffers="selectedOffers"
                    :config="config"
                    :config-id="cadh.configuration.id">
            </offers>

            <options :options="options"
                     :options-groups="optionsGroups"
                     :config="cadh.configuration"
                     :selected-options="selectedOptions"
                     v-if="showOptions">
            </options>

            <informations :informations="cadh.configuration.informations"
                          :config="config"
                          v-if="showInformations">
            </informations>

            <basket v-if="displayBasket"
                    :config="cadh.configuration"
                    :selected-options="selectedOptions"
                    :selected-offers="selectedOffers">
            </basket>

            <div class="mt-3" v-show="cadh !== null && anythingSelected">
                <h3 class="text-center main-title">Complément d'information</h3>

                <div class="panel panel-default mt-4 w-lg-75 ml-auto mr-auto">
                    <div class="panel-heading">Informations de la personne répondant</div>

                    <div class="panel-body">
                        <div class="form-group d-md-flex">
                            <label class="col-md-2 col-form-label">Nom</label>
                            <div class="col-md-10 input-group">
                                <input type="text" name="last_name" class="form-control" v-model="prestataire.last_name">
                            </div>
                        </div>

                        <div class="mt-2 alert alert-danger" v-if="submitted && prestataire.last_name === ''">
                            Le nom est requis.
                        </div>

                        <div class="form-group d-md-flex">
                            <label class="col-md-2 col-form-label">Prénom</label>
                            <div class="col-md-10 input-group">
                                <input type="text" name="first_name" class="form-control" v-model="prestataire.first_name">
                            </div>
                        </div>

                        <div class="mt-2 alert alert-danger" v-if="submitted && prestataire.first_name === ''">
                            Le prénom est requis.
                        </div>

                        <div class="form-group d-md-flex">
                            <label class="col-md-2 col-form-label">E-mail</label>
                            <div class="col-md-10 input-group">
                                <input type="text" class="form-control" v-model="prestataire.email">
                            </div>
                        </div>

                        <div class="mt-2 alert alert-danger" v-if="submitted && prestataire.email === ''">
                            L'email est requis.
                        </div>
                    </div>
                </div>

                <p class="w-lg-75 ml-auto mr-auto mt-3" v-html="getConfigKey('extra_field_title')">

                <div class="input-group w-lg-75 ml-auto mr-auto">
                    <textarea rows="6" class="form-control" v-model="extraInformation"></textarea>
                </div>

                <div v-if="msgError !== null && selectedMP.code !== '1'" class="w-lg-75 ml-auto mr-auto alert alert-danger mt-4">
                    {{ this.msgError }}
                </div>

                <div class="action-buttons text-center mt-3">
                    <div class="mt-5 mb-4 d-flex justify-content-center" v-if="cgu !== null && finalPrice === 0">
                        <md-checkbox class="mb-auto mt-auto"
                                     v-model="acceptedCgu">
                        </md-checkbox>
                        <div>
                            <span>Je certifie avoir lu et accepté </span>
                            <a :href="cgu.doc_url" target="_blank">les conditions générales d'utilisation</a>
                            <span> de {{ organism.value }}</span>
                        </div>
                    </div>

                    <md-button v-if="finalPrice === 0"
                               class="md-raised"
                               :disabled="!acceptedCgu"
                               @click="checkNoPayment">
                        Confirmer mon <span v-if="isPartenariat">partenariat</span><span v-if="!isPartenariat">adhésion</span>
                    </md-button>

                    <div v-show="finalPrice !== 0" class="w-lg-75 ml-auto mr-auto">
                        <div>
                            <div class="text-left">
                                <label class="col-form-label">Moyen de paiement</label>
                            </div>

                            <div class="text-left">
                                <p v-html="getConfigKey('payment_info')"></p>
                            </div>

                            <div>
                                <select class="form-control" v-model="selectedMP">
                                    <option v-for="paiement in moyensPaiement"
                                            :key="paiement.id"
                                            :value="paiement">
                                        {{ paiement.label }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="paiement-card mt-5" v-show="selectedMP.code === '1'">
                            <div class="paiement-card-title">Paiement sécurisé par carte bancaire</div>
                            <div class="paiement-card-body">
                                <div class="text-left font-weight-bold mb-5">
                                    Montant total à payer : {{ finalPrice }}€
                                </div>
                                <div class="d-flex">
                                    <div class='credit-card-inputs w-50'>
                                        <form method="POST">
                                            <input type="hidden" name="token" />
                                            <div id="card" class="field"></div>
                                            <div id="card-errors" role="alert"></div>
                                        </form>
                                    </div>

                                    <div class="w-50 p-3">
                                        <div class="d-flex card-types flex-wrap flex-row-reverse">
                                            <img class="m-2" src="/images/cadh/visa.png">
                                            <img class="m-2" src="/images/cadh/master-card.png">
                                            <img class="m-2" src="/images/cadh/american-express.png">
                                            <img class="m-2" src="/images/cadh/discover.png">
                                            <img class="m-2" src="/images/cadh/jcb.png">
                                            <img class="m-2" src="/images/cadh/diners-club.png">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="msgError !== null && selectedMP.code === '1'" class="w-lg-75 ml-auto mr-auto alert alert-danger mt-4">
                                {{ this.msgError }}
                            </div>

                        </div>

                        <div class="mt-3" v-if="selectedMP.id !== undefined">
                            <div class="mt-5 mb-4 text-left d-flex" v-if="cgu !== null">
                                <md-checkbox class="mb-auto mt-auto"
                                             v-model="acceptedCgu">
                                </md-checkbox>
                                <div>
                                    <span>Je certifie avoir lu et accepté </span>
                                    <a :href="cgu.doc_url" target="_blank">les conditions générales d'utilisation</a>
                                    <span> de {{ organism.value }}</span>
                                </div>
                            </div>

                            <md-button class="md-raised"
                                       :disabled="!acceptedCgu"
                                       v-if="selectedMP.code !== '1'"
                                       @click="checkNoPayment">
                                Confirmer mon <span v-if="isPartenariat">partenariat</span><span v-if="!isPartenariat">adhésion</span>
                            </md-button>

                            <md-button class="md-raised" v-else @click="checkPayment()" :disabled="!acceptedCgu">
                                Confirmer et payer mon <span v-if="isPartenariat">partenariat</span><span v-if="!isPartenariat">adhésion</span>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="displayThanks" class="container web-form-thanks pb-4 pt-4">
            <div class="v-alert" style="margin-bottom: 50px; margin-top: 50px">
                <div class="d-flex justify-content-start">
                    <div class="logo">
                        <img :src="logo.doc_url" v-if="logo !== null">
                    </div>

                    <div>
                        <div class="mt-4 main-content" v-html="getConfigKey('remerciement_1')"></div>

                        <div class="mt-3" v-if="selectedMP.description !== undefined &&
                                                (selectedMP.description !== null ||
                                                selectedMP.document !== null)">
                            <hr>

                            <h3>Réglez votre <span v-if="isPartenariat">partenariat</span><span v-if="!isPartenariat">adhésion</span></h3>

                            <div class="text-left main-content">
                                <div v-html="selectedMP.description"></div>
                            </div>
                            <div class="mt-2" v-if="selectedMP.document !== null">
                                <img :src="apiUrl + '/' + selectedMP.document">
                            </div>
                        </div>

                        <div style="margin-top: 40px" v-if="contact !== null">
                            <a :href="huboFrontURl + '/espace-pro/' + contact.token + '/solicitations'">
                                Revenir à mon espace pro <md-icon large>arrow_right_alt</md-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loading" class="loading">
            <md-progress-spinner :md-diameter="25"
                                 :md-stroke="3"
                                 md-mode="indeterminate">
            </md-progress-spinner>
        </div>

        <md-dialog-confirm
                :md-active.sync="displayConfirmNoPaiement"
                md-title="Confirmation de votre demande"
                md-confirm-text="Oui"
                md-cancel-text="Non"
                @md-confirm="answerWithoutPaiement()" />

        <md-dialog-confirm
                :md-active.sync="displayConfirmPaiement"
                md-title="Confirmation de votre demande"
                md-confirm-text="Oui"
                md-cancel-text="Non"
                @md-confirm="answerWithPaiement()" />

        <div id="test" :class="{displayFront: displayStripeModal}" v-show="displayStripeModal"></div>
    </div>
</template>

<script>
    import Offers from "./tools/Offers";
    import Options from "./tools/Options";
    import Informations from "./tools/Informations";
    import Basket from "./tools/Basket";

    export default {
        name: "Adhesion",
        components: { Basket, Informations, Options, Offers },
        data() {
            return {
                cadh: null,
                cadhRequest: null,
                adhesionName: null,
                filtered: false,
                options: [],
                optionsGroups: [],
                selectedOptions: [],
                selectedOffers: [],
                displayStripe: false,
                displayBasket: false,
                displayThanks: false,
                displayStripeModal: false,
                displayConfirmPaiement: false,
                displayConfirmNoPaiement: false,
                submitted: false,
                prestataire: {
                    email: '',
                    first_name: '',
                    last_name: ''
                },
                extraInformation: null,
                selectedMP: {},
                moyensPaiement: [],
                stripePk: null,
                stripeSk: null,
                optionsCard: null,
                optionsCVC: null,
                optionsExpired: null,
                loading: false,
                config: {},
                clientSecret: null,
                stripe: null,
                card: null,
                cadhAnswerId: null,
                msgError: null,
                apiUrl: process.env.VUE_APP_API_URL,
                huboFrontURl: process.env.VUE_APP_HUBO_FRONT_URL,
                logo: null,
                cgu: null,
                organism: null,
                contact: null,
                acceptedCgu: false
            }
        },
        methods: {
            getRequest() {
                this.loading = true;

                this.$http.get(this.apiUrl + '/api/adhesion/v1/' + this.$route.params.token).then(res => {
                    if(res.body.result === 'success') {
                        let cadh = res.body.data.cadh;

                        this.options           = res.body.data.options;
                        this.optionsGroups     = res.body.data.optionsGroups;
                        this.cadhRequest       = res.body.data.adhesion;
                        this.prestataire.email = this.cadhRequest.email_to;
                        this.moyensPaiement    = cadh.configuration.payment_options;
                        this.stripePk          = res.body.data.stripePK;
                        this.stripeSk          = res.body.data.stripeSK;
                        this.logo              = res.body.data.logo
                        this.contact           = res.body.data.contact
                        this.cgu               = res.body.data.cgu
                        this.organism          = res.body.data.organism

                        if(this.cadhRequest.provider !== null) {
                            this.adhesionName = this.cadhRequest.provider.last_version.name
                        } else {
                            this.adhesionName = this.cadhRequest.entity.name
                        }

                        cadh.configuration.informations.forEach(info => {
                            info.selected = false;
                        });

                        if(this.cgu === null) {
                            this.acceptedCgu = true
                        }

                        this.cadh = cadh;

                        this.setOptionsPrice();
                        this.initStripe();

                        this.loading = false;
                    }
                }, () => {
                    // géré par interceptor
                })
            },
            proceedPaiement(cadhAnswerId) {
                let payload = {
                    cadhAnswerId: cadhAnswerId
                };

                this.$http.post(this.apiUrl + '/api/adhesion/v1/payment/intent', payload).then(res => {
                    if(res.body.result === 'success') {
                        let clientSecret = res.body.data.client_secret;
                        let base_url = window.location.origin;

                        this.clientSecret = clientSecret;
                        this.cadhAnswerId = cadhAnswerId;

                        let existDiv = document.getElementById('test')

                        if(existDiv === null) {
                            let div = document.createElement('div');
                            div.id  = "test"
                            div.style ="position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 0;"
                            document.getElementsByClassName("adhesion")[0].appendChild(div);
                        }

                        this.stripe.confirmPaymentIntent(clientSecret, this.card,
                            {return_url: base_url+ '/result'}).then(res => {

                            let intent = res.paymentIntent;

                            if(intent !== undefined && intent.status === 'requires_action') {
                                this.displayStripeModal = true;
                                let iframe = document.createElement('iframe');
                                iframe.src = intent.next_action.redirect_to_url.url;
                                iframe.width = '100%';
                                iframe.height = '100%';
                                document.getElementById('test').appendChild(iframe);

                                this.loading = false;
                            } else if(intent !== undefined && intent.status === 'succeeded') {
                                this.loading = false;
                                this.displayThanks = true;

                                this.updateAnswerState('PAID', this.cadhAnswerId);
                                this.sendEmail();
                            } else {
                                this.loading = false;
                                this.updateAnswerState('FAILED', this.cadhAnswerId);
                                this.$bus.$emit('adhesion-error-notif', 'Erreur lors du paiement. Veuillez réassayer avec une autre méthode de paiement');

                                let div = document.getElementById('test')
                                div.style.zIndex = -1
                            }
                        })
                    }
                }, () => {
                    // géré par interceptor
                })
            },
            finalizePayment() {
                document.getElementById('test').remove();

                this.stripe.retrievePaymentIntent(this.clientSecret).then(result => {
                    if (result.error) {
                        this.$bus.$emit('adhesion-error-notif', 'Une erreure s\est produite lors du paiement. Le paiement n\'a pas été pris en compte.');
                        this.displayStripeModal = false;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            this.displayThanks = true;

                            this.updateAnswerState('PAID', this.cadhAnswerId);
                            this.sendEmail();
                        } else if (result.paymentIntent.status === 'requires_payment_method') {
                            this.updateAnswerState('FAILED', this.cadhAnswerId);

                            this.$bus.$emit('adhesion-error-notif', 'Erreur lors du paiement. Veuillez réassayer avec une autre méthode de paiement');
                            this.displayStripeModal = false;

                            let div = document.getElementById('test')
                            div.style.zIndex = -1
                        }
                    }
                })
            },
            checkPrestataireInfos() {
                return this.prestataire.email !== '' &&
                    this.prestataire.first_name !== '' &&
                    this.prestataire.last_name !== '';
            },
            buildPayload() {
                let options = [];
                let offers = [];
                let informations = [];

                this.selectedOptions.forEach(option => {
                    let opt = {
                        id: option.cadh_option_id,
                        quantity: option.quantity
                    };

                    options.push(opt);
                });

                this.selectedOffers.forEach(offer => {
                    let off = {
                        id: offer.cadh_offer_id,
                        quantity: offer.quantity
                    };

                    offers.push(off);
                });

                this.cadh.configuration.informations.forEach(info => {
                    if(info.selected) {
                        informations.push(info.id);
                    }
                });

                let payload = {
                    cadhFormId: this.cadhRequest.id,
                    amount: this.finalPrice * 100,
                    extraInfo: this.extraInformation,
                    moyenPaiement: parseInt(this.selectedMP.id),
                    options: options,
                    offers: offers,
                    informations: informations,
                    prestataire: this.prestataire
                };

                if(this.selectedMP === null && this.finalPrice === 0) {
                    payload.moyenPaiement = null;
                }

                return payload;
            },
            answerWithoutPaiement() {
                this.submitted = true;

                if(this.checkPrestataireInfos()) {
                    let payload = this.buildPayload();

                    this.loading = true;

                    this.$http.post(this.apiUrl +'/api/adhesion/v1/answer', payload).then(res => {
                        if(res.body.result === 'success') {
                            this.displayThanks = true;
                            this.loading = false;
                            this.cadhAnswerId = res.body.data.cadhAnswerId;

                            if(this.selectedMP.id === undefined && this.finalPrice === 0) {
                                this.updateAnswerState('NO_PAIEMENT', this.cadhAnswerId);
                            }

                            this.sendEmail();
                        }

                        this.submitted = false;
                    }, () => {
                        // géré par interceptor
                    });
                }
            },
            answerWithPaiement() {
                this.submitted = true;

                if(this.checkPrestataireInfos()) {
                    let payload = this.buildPayload();

                    this.loading = true;

                    this.$http.post(this.apiUrl +'/api/adhesion/v1/answer', payload).then(res => {
                        if(res.body.result === 'success') {
                            this.proceedPaiement(res.body.data.cadhAnswerId);
                        }

                        this.submitted = false;
                    }, () => {
                        // géré par interceptor
                    });
                }
            },
            updateAnswerState(state, cadhAnswerId) {
                if(state !== null) {
                    let payload = {
                        cadhAnswerId: cadhAnswerId,
                        state: state
                    };

                    this.$http.post(this.apiUrl + '/api/adhesion/v1/update-answer', payload).then(() => {}, () => {
                        // géré par interceptor
                    });
                }
            },
            sendEmail() {
                this.$http.get(this.apiUrl +'/api/adhesion/v1/answer/thanks/' + this.cadhAnswerId).then(() => {}, () => {
                    // géré par interceptor
                });
            },
            checkPayment() {
                this.msgError = null;

                let validCard = !this.card._invalid && this.card._complete;
                let validUser = this.checkPrestataireInfos();

                if(validCard && validUser) {
                    this.displayConfirmPaiement = true;
                } else if(!validCard) {
                    this.msgError = 'Les données de la cartes sont incomplètes ou invalides';
                } else {
                    this.msgError = 'Veuillez renseigner votre nom, prénom et adresse email';
                }
            },
            checkNoPayment() {
                this.msgError = null;

                let validUser = this.checkPrestataireInfos();

                if(validUser) {
                    this.displayConfirmNoPaiement = true;
                } else {
                    this.msgError = 'Veuillez renseigner votre nom, prénom et adresse email';
                }
            },
            initStripe() {
                this.stripe = Stripe(this.stripePk);
                let elements = this.stripe.elements();

                let elementStyles = {
                    base: {
                        color: '#32325D',
                        fontWeight: 500,
                        fontSize: '16px',
                        fontSmoothing: 'antialiased',

                        '::placeholder': {
                            color: '#9d9898',
                        },
                        ':-webkit-autofill': {
                            color: '#e39f48',
                        }
                    },
                    invalid: {
                        color: '#E25950',
                        '::placeholder': {
                            color: '#FFCCA5',
                        },
                    },
                };

                let elementClasses = {
                    focus: 'focused',
                    empty: 'empty',
                    invalid: 'invalid',
                };

                let card = elements.create('card', {style: elementStyles, classes: elementClasses});
                card.mount('#card');

                card.addEventListener('change', event => {
                    let displayError = document.getElementById('card-errors');

                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });

                this.card = card;
            },
            setOptionsPrice() {
                this.options.billable.forEach(option => {
                    if(option.price !== undefined) {
                        option.price = option.price / 100;
                    }
                });

                this.options.non_billable.forEach(option => {
                    if(option.price !== undefined) {
                        option.price = option.price / 100;
                    }
                });
            },
            getConfigKey(key) {

                if(this.cadh === null) {
                    return null
                }

                let config = this.cadh.configuration.configuration_keys.find(configKey => configKey.key === key)

                if(config !== null && config !== undefined) {
                    config = config.value
                }

                if (config === undefined && key == 'extra_field_title') {

                    config = "Informations complémentaires que vous souhaiteriez transmettre à l'office de tourisme."
                }

                if (config === undefined && key == 'basket_field_title') {

                    config = "<h3>Récapitulatif de mon adhésion</h3>"
                }

                return config
            }
        },
        computed: {
            finalPrice() {
                let price = 0;

                this.selectedOptions.forEach(option => {
                    if(option.priceT !== undefined && option.immediate) {
                        price += option.priceT;
                    }
                });

                this.selectedOffers.forEach(offer => {
                    if(offer.priceT !== undefined) {
                        price += offer.priceT;
                    }
                });

                this.displayBasket = this.selectedOffers.length + this.selectedOptions.length > 0;

                return price;
            },
            anythingSelected() {
                let selected = false;

                if(this.selectedOptions.length + this.selectedOffers.length > 0) {
                    selected = true;
                }

                this.cadh.configuration.informations.forEach(info => {
                    if(info.selected && !selected) {
                        selected = true;
                    }
                });

                return selected;
            },
            customLogo() {
                let customLogo = this.getConfigKey('custom_logo')

                console.log("customLogo : " + customLogo)

                if (customLogo === undefined) {
                    return false
                }
                else {
                    return this.apiUrl + '/' + customLogo
                }
            },
            showOfferName() {
                let offerNameDeactivated = this.getConfigKey('offer_name_deactivated')

                console.log("offerNameDeactivated : " + offerNameDeactivated)

                if (offerNameDeactivated === '1') {
                    return false
                }
                else {
                    return true
                }
            },
            showOptions() {
                let show = this.cadh !== null &&
                    this.cadh.configuration.options.length && this.filtered

                let oneOrMoreOffers = this.getConfigKey('one_offer_to_show_options')

                if(oneOrMoreOffers != 0) {
                    show = show && this.selectedOffers.length > 0
                }

                return show
            },
            showInformations() {

                let show = (this.cadh !== null && this.cadh.configuration.informations.length && this.filtered)

                let oneOrMoreOffers = this.getConfigKey('one_offer_to_show_informations')

                if ((oneOrMoreOffers !== undefined) && (oneOrMoreOffers !== null)) {

                    show = show && (this.selectedOffers.length > 0)
                }

                return show
            },
            isPartenariat() {

                let is_partenariat = this.getConfigKey('global_partenariat')

                if (is_partenariat === '1') {
                    return true
                }
                else {
                    return false
                }
            }
        },
        created() {
            this.getRequest();

            this.$bus.$on('filtered-offers', () => {
                this.filtered = true;
            });

            window.addEventListener('message', ev => {
                if (ev.data === '3DS-authentication-complete') {
                    this.finalizePayment();
                }
            }, false);
        },
        beforeDestroy() {
            this.$bus.$off('filtered-offers');
        }
    }
</script>

<style scoped>
    #test {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    #test.displayFront {
        z-index: 1;
    }

    .StripeElement {
        box-sizing: border-box;

        height: 40px;

        padding: 10px 12px;

        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #868686;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>
