<template>
    <div :id="'message-' + message.id"
         :class="'alert-content alert alert-dismissible fade show alert-' + message.type" role="alert">

        <button type="button" class="close" @click="destroy()">
            <span aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
        </button>
        <div class="d-flex">
            <div class="mt-auto mb-auto mr-3">
                <md-icon :class="'icon-' + message.type">{{ message.icon }}</md-icon>
            </div>
            <div :id="'msg-content-' + message.id"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MessageCard",
        props: ['message'],
        methods: {
            destroy() {
                this.$bus.$emit('delete-message', this.message.id);
            }
        },
        mounted() {
            let msgDiv = document.querySelector('#msg-content-' + this.message.id);

            if(this.message.toAppend === undefined) {
                msgDiv.innerHTML = this.message.content;
            } else {
                msgDiv.append(this.message.content);
            }
        }
    }
</script>