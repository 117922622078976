<template>
    <div>
        <div v-if='useAccordeon'>
            <md-list>
                <template v-for="(options, groupName) in optionsGroups" >

                    <template v-if="groupName != ''">

                        <md-list-item md-expand :md-expanded="! deactivateAccordeon" :key="groupName">

                            <span class="md-list-item-text">
                                <span v-html="groupName"></span>
                            </span>

                            <md-list slot="md-expand">
                                <md-list-item class="md-inset" v-for="option in options" :key="option.id">

                                    <div class="mt-auto mb-auto option-label"
                                         :style="'padding-left:' + option.paddingLeft + 'px'"
                                         :class="{'font-weight-normal': option.price !== undefined && option.price !== null}">

                                        <span v-html="option.name"></span>

                                        <span v-if="option.description !== undefined && option.description !== null">
                                               <md-icon>help</md-icon>
                                               <md-tooltip md-delay="300" md-direction="bottom" >
                                                   {{ option.description }}
                                               </md-tooltip>
                                           </span>
                                    </div>

                                    <div class="options-quantity d-flex">
                                        <div class="mt-auto mb-auto price">

                                            <div v-if="option.price !== undefined && option.price !== null">
                                                {{ option.price }}€
                                            </div>

                                        </div>

                                        <div v-if="option.price !== undefined"
                                             class="mt-auto mb-auto quantity-container d-flex">
                                            <span>Quantité</span>
                                            <input type="number"
                                                   class="form-control"
                                                   min="1"
                                                   :disabled="option.multiple"
                                                   value="1"
                                                   :id="'qt-opt-' + option.option_id">
                                        </div>

                                        <div v-if="option.price !== undefined"
                                             class="mt-auto mb-auto">
                                            <md-button class="md-raised"
                                                       @click="insertOption(option)"
                                                       v-if="option.immediate">
                                                Ajouter
                                            </md-button>
                                            <md-button class="md-raised"
                                                       @click="insertOption(option)"
                                                       v-else>
                                                Réserver
                                            </md-button>
                                        </div>
                                    </div>
                                </md-list-item>
                            </md-list>
                        </md-list-item>
                    </template>

                    <template v-else>

                        <md-list-item class="md-inset" v-for="option in options" :key="option.id">

                            <div class="mt-auto mb-auto option-label"
                                 :class="{'font-weight-normal': option.price !== undefined && option.price !== null}">

                                <p v-html="option.name"></p>

                                <span v-if="option.description !== undefined && option.description !== null">
                                   <md-icon>help</md-icon>
                                   <md-tooltip md-delay="300" md-direction="bottom" >
                                       {{ option.description }}
                                   </md-tooltip>
                               </span>
                            </div>

                            <div class="options-quantity d-flex">
                                <div class="mt-auto mb-auto price">

                                    <div v-if="option.price !== undefined && option.price !== null">
                                        {{ option.price }}€
                                    </div>

                                </div>

                                <div v-if="option.price !== undefined"
                                     class="mt-auto mb-auto quantity-container d-flex">
                                    <span>Quantité</span>
                                    <input type="number"
                                           class="form-control"
                                           min="1"
                                           :disabled="option.multiple"
                                           value="1"
                                           :id="'qt-opt-' + option.option_id">
                                </div>

                                <div v-if="option.price !== undefined"
                                     class="mt-auto mb-auto">
                                    <md-button class="md-raised"
                                               @click="insertOption(option)"
                                               v-if="option.immediate">
                                        Ajouter
                                    </md-button>
                                    <md-button class="md-raised"
                                               @click="insertOption(option)"
                                               v-else>
                                        Réserver
                                    </md-button>
                                </div>
                            </div>

                        </md-list-item>
                    </template>
                </template>
            </md-list>
        </div>

        <div v-else v-for="option in options"
             class="mb-2 font-weight-bold d-flex">
            <div class="mt-auto mb-auto option-label"
                 :style="'padding-left:' + option.paddingLeft + 'px'"
                 :class="{'font-weight-normal': option.price !== undefined && option.price !== null}">

                <span v-html="option.name"></span>

                <span v-if="option.description !== undefined && option.description !== null">
                       <md-icon>help</md-icon>
                       <md-tooltip md-delay="300" md-direction="bottom" >
                           {{ option.description }}
                       </md-tooltip>
                   </span>
            </div>

            <div class="options-quantity d-flex">
                <div class="mt-auto mb-auto price">

                    <div v-if="option.price !== undefined && option.price !== null">
                        {{ option.price }}€
                    </div>

                </div>

                <div v-if="option.price !== undefined"
                     class="mt-auto mb-auto quantity-container d-flex">
                    <span>Quantité</span>
                    <input type="number"
                           class="form-control"
                           min="1"
                           :disabled="option.multiple"
                           value="1"
                           :id="'qt-opt-' + option.option_id">
                </div>

                <div v-if="option.price !== undefined"
                     class="mt-auto mb-auto">
                    <md-button class="md-raised"
                               @click="insertOption(option)"
                               v-if="option.immediate">
                        Ajouter
                    </md-button>
                    <md-button class="md-raised"
                               @click="insertOption(option)"
                               v-else>
                        Réserver
                    </md-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OptionList",
        props: ['options', 'selectedOptions', 'config', 'optionsGroups'],
        methods: {
            insertOption(option) {
                let quantity = document.querySelector('#qt-opt-' + option.option_id).value;
                let toInsert = true;

                if(quantity === '') {
                    quantity = 1;
                } else {
                    quantity = parseInt(quantity);
                }

                document.querySelector('#qt-opt-' + option.option_id).value = 1;

                let exist = false;

                this.selectedOptions.forEach(opt => {
                    if(opt.cadh_option_id === option.option_id) {
                        exist = true;

                        if(!option.multiple) {
                            opt.quantity += quantity;

                            if(opt.priceT !== undefined) {
                                opt.priceT = parseFloat(opt.priceU) * opt.quantity;
                            }
                        } else {
                            toInsert = false;
                        }
                    }
                });

                if(!exist) {
                    let insert = {
                        cadh_option_id: option.option_id,
                        quantity: quantity,
                        name: option.label,
                        immediate: option.immediate
                    };

                    if(option.price !== undefined && option.price !== null) {
                        insert.priceU = parseFloat(option.price);
                        insert.priceT = parseFloat(option.price) * quantity;
                        insert.multiple = option.multiple;
                    }

                    this.selectedOptions.push(insert);
                }

                let span = document.createElement('span');
                span.classList.add('span-link');
                span.innerHTML = "Voir mon récapitulatif";
                span.addEventListener('click', () => {
                    this.scrollBasket();
                });

                let div = document.createElement('div');

                if(toInsert) {
                    div.innerHTML = "L'option « "+ option.name +" » a été ajoutée à votre adhésion.<br>";
                    div.append(span);

                    this.$bus.$emit('adhesion-success-notif', div);
                } else {
                    div.innerHTML = "L'option « "+ option.name +" » ne peut être sélectionnée qu'une seule fois.<br>";
                    div.append(span);

                    this.$bus.$emit('adhesion-error-notif', div);
                }
            },
            scrollBasket() {
                let element = document.getElementById("basket");
                let top = element.offsetTop;
                window.scrollTo(0, top);
            },
            getConfigKey(key) {

                let config = this.config.configuration_keys.find(configKey => configKey.key === key)

                if(config !== null && config !== undefined) {
                    config = config.value
                }

                return config
            }
        },
        computed: {
            useAccordeon() {

                let use_accordeon = this.getConfigKey('options_use_accordeon')

                if (use_accordeon === '1') {
                    console.log("use accordeon")
                    return true
                }
                else {
                    console.log("not use accordeon")
                    return false
                }
            },
            deactivateAccordeon() {

                let activate = false

                for (let group in this.optionsGroups) {

                    let options = this.optionsGroups[group]
                    console.log(options)

                    if (options.length >= 5) {
                        console.log(options.length)
                        activate = true
                        break
                    }
                }

                console.log('activate : ' + activate)

                return activate
            },
        },
    }
</script>
