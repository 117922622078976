<template>
    <div class="mt-3 mb-3" v-if="informations.length > 0">
        <div class="mb-3 main-title text-center">
            <h3>Informations</h3>
        </div>

        <div class="mt-4 mb-3 main-content" v-html="$parent.getConfigKey('informations_aide_1')"></div>

        <div class="mt-3 main-content" v-for="information in informations">
            <md-checkbox class="mb-0 mt-0"
                         v-model="information.selected">
                {{ information.name }}
            </md-checkbox>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Informations",
        props: ['informations', 'config']
    }
</script>
