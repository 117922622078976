require('dotenv').config();
window.$ = window.jQuery = require('jquery');

// Depedencies
import Vue from 'vue';
import VueResource from 'vue-resource';
import VueMaterial from 'vue-material';
import VueMultiselect from 'vue-multiselect';
import MessagesPanel from "./components/tools/MessagesPanel";
import MessageCard from "./components/tools/MessageCard";

// utils
import router from './router';

import '../src/assets/css/app.scss'

// Components
import App from './App';

Vue.use(VueMaterial);
Vue.use(VueResource);

Vue.component('multiselect', VueMultiselect);
Vue.component('message-panel', MessagesPanel);
Vue.component('message-card', MessageCard);

Vue.prototype.$bus = new Vue();

Vue.http.interceptors.push((req, next) => {
    next(res => {
        if(res.status === 500) {
            Vue.prototype.$bus.$emit('unhandled-error', res);
        }

        if(res.status === 200) {
            if(res.body.result === 'error') {
                Vue.prototype.$bus.$emit('handled-error', res);
            } else {
                Vue.prototype.$bus.$emit('success-request', res);
            }
        }
    })
});

const main = new Vue({
    router: router,
    render: h => h(App)
}).$mount('#app');

export default main;