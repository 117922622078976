<template>
    <div class="h-100">
        <router-view></router-view>
        <message-panel></message-panel>
    </div>
</template>

<script>
export default {
    name: "App"
}
</script>
